




















































































import { Component, Vue } from 'vue-property-decorator'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import SelectStoreService from '@/services/SelectStoreService'
import ChangeStoreService from '@/services/ChangeStoreService'
import { namespace } from 'vuex-class'
import LogoService from "@/services/LogoService";
const ChangeProfile = namespace('ChangeProfile')

@Component({
  components: {
    LayoutCompact
  }
})
export default class MemberSelectStore extends Vue {
  public items: string = ''
  public isHaveStore: boolean = true
  public isZipcodeChange: boolean = localStorage.getItem('zipcode_change')
    ? Boolean(localStorage.getItem('zipcode_change'))
    : false
  public image: string = ''

  @ChangeProfile.Action
  private CHANGE_PROFILE!: (data: any) => any
  private data: any
  private zipcode: any

  async created() {
    await LogoService.getAvatar().then((res: any) => {
      this.image = res.data.items
    })

    if (localStorage.getItem('member')) {
      let member: any = localStorage.getItem('member')
      this.data = JSON.parse(member)
      this.zipcode = this.data.zip_code
      SelectStoreService.selectStore(this.zipcode).then(response => {
        if (response.data.items.length !== 0) {
          this.isHaveStore = true
          this.items = response.data.items
        } else {
          this.isHaveStore = false
        }
      })
    }
  }

  public selectStore(store_id: any) {
    const storedUser = localStorage.getItem('user')
    let user = storedUser ? JSON.parse(storedUser) : null
    let old_store_id = user.user.store_id
    ChangeStoreService.changeStore(store_id, old_store_id).then(
      (response: any) => {
        if (response) {
          let profileUser: any = JSON.parse(localStorage.getItem('user') || '')
          profileUser.user.store_uuid = response.data.items.store_uuid
          profileUser.user.store_id = response.data.items.store_id
          localStorage.setItem('user', JSON.stringify(profileUser))
          this.CHANGE_PROFILE(profileUser)
          localStorage.setItem('store_uuid', response.data.items.store_uuid)
          localStorage.setItem('store_id', response.data.items.store_id)
          localStorage.removeItem('member')
          this.$router.push({ name: 'home' })
        }
      },
      (error: {
        response: { data: any; status: any }
        message: any
        toString: () => any
      }) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
        if (error.response.status === 500) {
          this.$bvModal.show('change-store-fail')
        }
      }
    )
  }

  public clickOk() {
    this.$router.push({ name: 'member-no-select-store' })
  }

  public closeModal() {
    this.$bvModal.hide('change-store-fail')
  }
}
