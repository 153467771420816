import Const from '../constants'
import axiosApiInstance from '../interceptors'

class ChangeStoreServeice {
  changeStore(store_id: any, old_store_id: any) {
    const profile = {
      old_store_id: old_store_id,
      store_id: store_id
    }
    return axiosApiInstance.put(Const.api_url + 'member/change-store', profile)
  }
}

export default new ChangeStoreServeice()
